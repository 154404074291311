import { Avatar } from "@fluentui/react-components";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: "white",
  ...draggableStyle,
});

const DroppableList = ({ droppableId, items, searchQuery }) => {
  const filteredItems = items.filter((item) =>
    item?.value?.includes(searchQuery)
  );

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
          className="rounded-l shadow p-2 w-full bg-white border-2  border-gris"
        >
          {filteredItems.map((item, index) => (
            <Draggable key={item.value} draggableId={item.value} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                  className="rounded-full p-2 mb-2 text-black  font-semibold text-xs flex items-center ring-1 ring-gris"
                >
                  <Avatar
                    size={20}
                    name={item.value}
                    image={item.photoUrl ? { src: item.photoUrl } : null}
                  ></Avatar>
                  &nbsp;&nbsp;
                  {item.value}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const DualListDragDropUsers = ({
  usersOptions,
  usersSelected,
  setUsersOptions,
  setUsersSelected,
}) => {
  const [searchLeft, setSearchLeft] = useState("");
  const [searchRight, setSearchRight] = useState("");

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const moveResult = move(
      source.droppableId === "droppableLeft" ? usersOptions : usersSelected,
      source.droppableId === "droppableLeft" ? usersSelected : usersOptions,
      draggableId
    );

    if (source.droppableId === "droppableLeft") {
      setUsersOptions(moveResult.droppableLeft);
      setUsersSelected(moveResult.droppableRight);
    } else {
      setUsersOptions(moveResult.droppableRight);
      setUsersSelected(moveResult.droppableLeft);
    }
  };

  const move = (source, destination, draggableId) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const index = sourceClone.findIndex((item) => item.value === draggableId);

    if (index === -1) {
      return { droppableLeft: sourceClone, droppableRight: destClone };
    }

    const [removed] = sourceClone.splice(index, 1);
    destClone.push(removed);

    return {
      droppableLeft: sourceClone,
      droppableRight: destClone,
    };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex gap-4 items-start justify-between w-full">
        <div className="w-1/2">
          <input
            type="text"
            placeholder="Search by UPN"
            value={searchLeft}
            onChange={(e) => setSearchLeft(e.target.value)}
            className="mb-2 p-2 border border-gray-300 w-full rounded-l h-7 text-xs text-black"
          />
          <div className="h-[30vh] overflow-y-scroll rounded-l">
            <DroppableList
              droppableId="droppableLeft"
              items={usersOptions}
              searchQuery={searchLeft}
            />
          </div>
        </div>
        <div className="w-1/2">
          <input
            type="text"
            placeholder="Search by UPN"
            value={searchRight}
            onChange={(e) => setSearchRight(e.target.value)}
            className="mb-2 p-2 border border-gray-300 w-full rounded-l h-7 text-xs text-black"
          />
          <div className="h-[30vh] overflow-y-scroll rounded-l">
            <DroppableList
              droppableId="droppableRight"
              items={usersSelected}
              searchQuery={searchRight}
            />
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default DualListDragDropUsers;
