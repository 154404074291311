import http from "../../http-common";

const validateMsal = ({ data }) => {
  return http.post(`/api/v1/auth/msalValidate`, data);
};

const ValidateMsalService = {
  validateMsal,
};

export default ValidateMsalService;
