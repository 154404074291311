import http from "../../http-common";

const getPermissions = () => {
  return http.get(`/api/v1/realtime/permissions`);
};

const realtime = ({ msalT }) => {
  return http.post(`/api/v1/realtime`, { msalT });
};

const RealtimeService = {
  getPermissions,
  realtime,
};

export default RealtimeService;
