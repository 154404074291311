import { EuiLoadingSpinner } from "@elastic/eui";
import { Avatar } from "@fluentui/react-components";

const RealtimeAgents = ({ users, isQueueSelected = true }) => {
  const agentBoxPaddingAndGapAdjustment = "px-2";

  const boxWidthClass = isQueueSelected
    ? "w-[calc(20%-0.7rem)]"
    : "w-[calc(14.28%-0.7rem)]";

  return (
    <div className="w-full pt-2 px-2">
      <div className="flex flex-row flex-wrap gap-1 overflow-auto max-h-[90vh]">
        {users.map((user) => (
          <div
            key={user.id}
            className={`m-1 relative rounded-xl dark:bg-white/5 bg-white ring-[1px] ring-gris dark:ring-0 p-2 flex items-center ${agentBoxPaddingAndGapAdjustment} ${boxWidthClass}`}
          >
            <Avatar
              size={48}
              name={user.name}
              image={user.photoUrl ? { src: user.photoUrl } : null}
              badge={
                user.status
                  ? {
                      status:
                        user.status.charAt(0).toLowerCase() +
                        user.status.slice(1),
                      size: "medium",
                    }
                  : {
                      icon: <EuiLoadingSpinner />,
                    }
              }
              className="flex-shrink-0"
            />
            <div className="flex-1 min-w-0 ml-2">
              <div className="truncate text-neutral-800 dark:text-white text-sm font-semibold">
                {user.name}
              </div>
              <div className="text-neutral-800 dark:text-white text-xs font-light">
                {user.activity}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealtimeAgents;
