import React, { useEffect, useState } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import VoosterLogoWhite from "../../assets/images/LogoWhite.svg";
import VoosterLogoBlack from "../../assets/images/LogoBlack.svg";
import { useDarkMode } from "../../context/DarkModeContext";

const RealtimeQueues = ({ queues }) => {
  const { darkMode } = useDarkMode();

  const [logo, setLogo] = useState(VoosterLogoBlack);
  const [color, setColor] = useState("#c084fc");

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    if (isDarkMode) {
      document.body.classList.add("dark");
      setLogo(VoosterLogoWhite);
      setColor("#6DD1B0");
    } else {
      document.body.classList.remove("dark");
      setLogo(VoosterLogoBlack);
      setColor("#c084fc");
    }
  }, [darkMode]);
  return (
    <div
      className="pt-3 pl-2 flex flex-col space-y-3 overflow-auto"
      style={{ height: "90vh" }}
    >
      {queues.map((queue, index) => (
        <div
          key={index}
          className="flex-grow-0 flex-shrink-0  rounded-xl dark:bg-white/5 bg-white p-3 ring-[1px] ring-gris dark:ring-0 w-full"
          style={{ height: "calc(92vh * 0.31)" }}
        >
          {queue.loading ? (
            <>
              <div className="text-2xl font-semibold mb-5 mt-2">
                <text className="dark:bg-[#6DD1B0]/20 dark:text-[#6DD1B0] text-purple-400 bg-purple-400/20 rounded-xl py-0.5 px-4">
                  {queue.name}
                </text>
              </div>
              <div className="relative top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 dark:border-[#6DD1B0] border-purple-400"></div>
                <img
                  alt="a"
                  src={logo}
                  className="rounded-full h-28 w-28 absolute"
                />
              </div>
            </>
          ) : (
            <div className="flex justify-between w-full h-full">
              <div className="w-[50%] h-full">
                <div className="text-2xl font-semibold mb-5 mt-2">
                  <text className="dark:bg-[#6DD1B0]/20 dark:text-[#6DD1B0] text-purple-400 bg-purple-400/20 rounded-xl py-0.5 px-4">
                    {queue.name}
                  </text>
                </div>

                <div className="h-full w-full text-white">
                  <ResponsiveContainer width="100%" height="70%">
                    <PieChart>
                      <defs>
                        <radialGradient
                          id="radialBG"
                          cx="50%"
                          cy="50%"
                          r="50%"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0%" stopColor="#dac157" />
                          <stop offset="100%" stopColor="#c19c00" />
                          <animate
                            attributeName="r"
                            values="0%;100%;0%"
                            dur="2s"
                            repeatCount="indefinite"
                          />
                        </radialGradient>
                      </defs>
                      {queue.calls_on_hold_quantity >= 1 && (
                        <Pie
                          data={[{ name: "Background", value: 100 }]}
                          stroke="0"
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          outerRadius="80%"
                          fill="url(#radialBG)"
                          startAngle={90}
                          endAngle={-270}
                          isAnimationActive={false}
                        />
                      )}
                      <Pie
                        data={[
                          { name: "Filled", value: queue.asr },
                          { name: "Empty", value: 100 - queue.asr },
                        ]}
                        cx="50%"
                        cy="50%"
                        innerRadius="80%"
                        outerRadius="100%"
                        fill={darkMode ? "green" : "purple"}
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                      >
                        <Cell key={`cell-filled`} fill={color} />
                        <Cell key={`cell-empty`} fill="#ddd" />
                      </Pie>

                      <text
                        x="50%"
                        y="50%"
                        dy="-3.5em"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="text-xs dark:fill-white"
                      >
                        {queue.asr ? parseFloat(queue.asr).toFixed(0) : 0}% ASR
                      </text>

                      <text
                        x="50%"
                        y="45%"
                        dy="0.3em"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="text-6xl font-semibold dark:fill-white"
                      >
                        {queue.calls_on_hold_quantity}
                      </text>
                      <text
                        x="50%"
                        y="87%"
                        dy="-2em"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="text-xs dark:fill-white"
                      >
                        Waiting Calls
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="w-[50%] h-full">
                <div className="flex-col justify-between h-full">
                  <div className="h-[50%]">
                    <div className="text-base font-semibold flex justify-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                      Free Agents
                    </div>
                    <div className="flex justify-end items-center w-full text-end mt-5 mb-5">
                      <div className="dark:bg-[#6DD1B0]/20 dark:text-[#6DD1B0] text-purple-400 bg-purple-400/20 h-16 w-16 flex items-center justify-center rounded-full text-5xl">
                        {queue.agents_available}
                      </div>
                    </div>
                  </div>
                  <div className="h-[50%]">
                    <div className="text-right text-s">
                      Attended:{" "}
                      <text className="font-semibold">
                        {queue.attended_calls}
                      </text>
                    </div>
                    <div className="text-s text-right">
                      Non-Attended:{" "}
                      <text className="font-semibold">
                        {queue.non_attended_calls}
                      </text>
                    </div>
                    <div className="text-s text-right">
                      Average Handle Time (AHT):{" "}
                      <text className="font-semibold">{queue.talk_time}</text>
                    </div>
                    <div className="text-s text-right">
                      Average Ringing Time:{" "}
                      <text className="font-semibold">{queue.hold_time}</text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RealtimeQueues;
