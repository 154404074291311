import React, { useEffect, useState } from "react";
import AdminService from "../../services/admin/admin";

const AdminDeleteUser = ({ userSelected, setShowAdminDeleteUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState({ status: "", message: "" });
  const [countdown, setCountdown] = useState(5);
  const handleSave = () => {
    setResponse({ status: "", message: "" });
    setIsLoading(true);

    AdminService.deleteUser({ userId: userSelected._id })
      .then(({ data: { status, message } }) => {
        setResponse({
          status: "success",
          message: "User deleted successfully",
        });
        setIsLoading(false);
        const intervalId = setInterval(() => {
          setCountdown((currentCountdown) => {
            if (currentCountdown <= 1) {
              clearInterval(intervalId);
              setShowAdminDeleteUser(false);
              return 0;
            }
            return currentCountdown - 1;
          });
        }, 1000);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact adminisitrator support@astroline.com",
          });
        }
      );
  };
  useEffect(() => {
    return () => {
      setCountdown(0);
    };
  }, []);
  return (
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div class="bg-white dark:bg-[#191919] dark:text-white rounded-lg shadow-lg p-4 max-w-xl w-full">
        <div className=" gap-4 w-full">
          <div className="mb-4 w-full">
            <label className="text-2xl flex items-center mb-6 mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
              Deleting user:{" "}
              <text className="text-red-400 ml-2">{userSelected.email}</text>
            </label>
            <text className="text-lg">
              <div className="font-normal">
                Are you sure you want to delete the User?
              </div>{" "}
              <div className="font-bold flex items-end gap-2">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>
                This change cannot be undone
              </div>
            </text>
          </div>
        </div>
        <div className="font-semibold text-sm mt-[6vh]">
          {response && response.status && response.message ? (
            <>
              {response.status === "error" ? (
                <div className="text-red-500">{response.message}</div>
              ) : (
                <div className="text-green-600">
                  {response.status === "success" && countdown > 0
                    ? `User deleted successfully: closing in ${countdown} second(s)...`
                    : response.message}
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="justify-between w-full items-center flex mt-2">
          <button
            onClick={() => setShowAdminDeleteUser(false)}
            className="rounded-full  font-semibold py-1 px-6 text-xl dark:text-white"
          >
            No, take me back
          </button>
          <button
            onClick={handleSave}
            className={`rounded-full font-semibold dark:text-white py-1 px-6 text-xl`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                  role="status"
                ></div>
                <span className="visually-hidden ml-3">Loading...</span>
              </div>
            ) : (
              "Yes, proceed"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDeleteUser;
