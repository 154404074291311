import http from "../../http-common";

const getUsers = () => {
  return http.get(`/api/v1/users/list`);
};

const getMsGraphUsers = () => {
  return http.get(`/api/v1/admin/msGraphUsers`);
};

const getAllQueues = () => {
  return http.get(`/api/v1/admin/getAllQueues`);
};

const createUser = ({ data }) => {
  return http.post(`/api/v1/users/create`, data);
};

const updateUser = ({ data, userId }) => {
  return http.put(`/api/v1/users/update?userId=${userId}`, data);
};

const deleteUser = ({ userId }) => {
  return http.delete(`/api/v1/users/delete?userId=${userId}`);
};
const AdminService = {
  getUsers,
  getMsGraphUsers,
  getAllQueues,
  createUser,
  updateUser,
  deleteUser,
};

export default AdminService;
