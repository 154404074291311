import React, { useState, useEffect, memo } from "react";
import Select from "react-select";
import AdminService from "../../services/admin/admin";
import makeAnimated from "react-select/animated";
import DualListDragDropUsers from "./AdminDragAndDropUsers";
import GraphService from "../../services/msGraph/msGraph";
import useAuthToken from "../../hooks/useAuthToken";
import { Avatar } from "@fluentui/react-components";
const DualListDragDropMemoized = memo(DualListDragDropUsers);

const AdminAddUser = ({ setShowAdminAddUser }) => {
  const animatedComponents = makeAnimated();

  const [isLoading, setIsLoading] = useState(true);
  const [msGraphUserList, setMsgraphUserList] = useState([]);
  const [msGrapDropableList, setMsgraphDropableList] = useState([]);
  const { token } = useAuthToken();

  const [msGrapDropableListSelected, setMsgraphDropableListSelected] = useState(
    []
  );

  const [queuesDropableList, setQueuesDropableList] = useState([]);
  const [queuesDropableListSelected, setQueuesDropableListSelected] = useState(
    []
  );

  const [selectedMsUser, setSelectedUser] = useState();

  const [countdown, setCountdown] = useState(5);
  const [roles, setRoles] = useState([]);

  const roleOptions = [
    {
      value: "User.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-white bg-gray-600">
          User.Read
        </span>
      ),
    },
    {
      value: "User.ReadWrite",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1  text-white bg-gray-600">
          User.ReadWrite
        </span>
      ),
    },
    {
      value: "Realtime.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-white bg-gray-600">
          Realtime.Read
        </span>
      ),
    },
  ];

  const handleRoleChange = (selectedOptions) => {
    setRoles(selectedOptions);
  };

  const [response, setResponse] = useState({ status: "", message: "" });

  useEffect(() => {
    const loadUsers = async () => {
      AdminService.getMsGraphUsers()
        .then(async ({ data: { users } }) => {
          const usersWithPhotos = await Promise.all(
            users.map(async (user) => {
              const photoUrl = "Avatar";
              // const photoUrl = await GraphService.getPhoto(token, user.mail);
              return { ...user, photoUrl, value: user.mail, id: user.id };
            })
          );
          const options = usersWithPhotos.map((user) => ({
            value: user.mail,
            mail: user.mail,
            name: user.givenName,
            surname1: user.surname,
            label: (
              <>
                <Avatar
                  size={20}
                  name={user.mail}
                  image={user.photoUrl ? { src: user.photoUrl } : null}
                ></Avatar>
                &nbsp;&nbsp;
                {user.mail}
              </>
            ),
          }));

          setMsgraphUserList(options);
          setMsgraphDropableList(usersWithPhotos);
          setIsLoading(false);
        })
        .catch((error) => {});
      AdminService.getAllQueues()
        .then(async ({ data: { queues } }) => {
          const queuesToSet = queues.map((queue) => {
            return {
              value: queue,
              label: queue,
            };
          });
          setQueuesDropableList(queuesToSet);
          setIsLoading(false);
        })
        .catch((error) => {});
    };
    loadUsers();
  }, [token]);

  const handleSave = () => {
    const data = {
      name: selectedMsUser.name,
      surname1: selectedMsUser.surname1,
      email: selectedMsUser.mail,
      roles: roles.map((role) => {
        return role.value;
      }),
      permissions: {
        agents: msGrapDropableListSelected.map((agent) => {
          return agent.id;
        }),
        queues: queuesDropableListSelected.map((queue) => {
          return queue.value;
        }),
      },
    };
    AdminService.createUser({ data })
      .then(() => {
        setResponse({
          status: "success",
          message: `User created succesfully successfully`,
        });
        setIsLoading(false);
        const intervalId = setInterval(() => {
          setCountdown((currentCountdown) => {
            if (currentCountdown <= 1) {
              clearInterval(intervalId);
              setShowAdminAddUser(false);
              return 0;
            }
            return currentCountdown - 1;
          });
        }, 1000);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact administrator support@astroline.com",
          });
        }
      );
  };
  const tienePermisoRealtimeRead = roles.some(
    (role) => role.value === "Realtime.Read"
  );

  useEffect(() => {
    return () => {
      setCountdown(0);
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white dark:bg-[#191919] dark:text-white rounded-lg shadow-lg p-4 max-w-[80%] w-full">
        <div className="mb-4 w-full">
          <label className="text-2xl flex items-center mb-6 mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
            Creating new user
          </label>
        </div>
        <div className="mb-4"></div>
        <div className="mb-4">
          <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 48 48"
              className="mr-1"
            >
              <path
                fill="#ff5722"
                d="M6 6H22V22H6z"
                transform="rotate(-180 14 14)"
              ></path>
              <path
                fill="#4caf50"
                d="M26 6H42V22H26z"
                transform="rotate(-180 34 14)"
              ></path>
              <path
                fill="#ffc107"
                d="M26 26H42V42H26z"
                transform="rotate(-180 34 34)"
              ></path>
              <path
                fill="#03a9f4"
                d="M6 26H22V42H6z"
                transform="rotate(-180 14 34)"
              ></path>
            </svg>
            Select a user from your tenant
          </label>
          <Select
            isLoading={isLoading}
            placeholder="Search by UPN"
            options={msGraphUserList}
            value={selectedMsUser}
            onChange={setSelectedUser}
            getOptionLabel={(option) => (
              <span className="inline-flex items-center px-2  text-xs rounded-xl font-medium mr-1 mb-1 text-black">
                {option.label}
              </span>
            )}
          />
        </div>
        <div>
          {selectedMsUser && (
            <>
              <div className="mb-4">
                <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width="20"
                    height="20"
                    className=" mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                    />
                  </svg>
                  Permissions
                </label>
                <Select
                  placeholder="Select Roles"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={roleOptions}
                  styles={{ width: 200 }}
                  onChange={handleRoleChange}
                  value={roles}
                />
              </div>
              <div className="mt-4" />
              {tienePermisoRealtimeRead && (
                <div className="flex justify-between">
                  <div className="w-[45%]">
                    <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 48 48"
                        className="mr-1"
                      >
                        <path
                          fill="#ff5722"
                          d="M6 6H22V22H6z"
                          transform="rotate(-180 14 14)"
                        ></path>
                        <path
                          fill="#4caf50"
                          d="M26 6H42V22H26z"
                          transform="rotate(-180 34 14)"
                        ></path>
                        <path
                          fill="#ffc107"
                          d="M26 26H42V42H26z"
                          transform="rotate(-180 34 34)"
                        ></path>
                        <path
                          fill="#03a9f4"
                          d="M6 26H22V42H6z"
                          transform="rotate(-180 14 34)"
                        ></path>
                      </svg>
                      Realtime - Agents
                    </label>
                    {isLoading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <DualListDragDropMemoized
                        usersOptions={msGrapDropableList}
                        setUsersOptions={setMsgraphDropableList}
                        usersSelected={msGrapDropableListSelected}
                        setUsersSelected={setMsgraphDropableListSelected}
                      />
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center w-[5%]">
                    <div className="bg-gris w-px h-[80%]"></div>
                  </div>
                  <div className="w-[45%]">
                    <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 235 235"
                        fill="none"
                        className="mr-1"
                      >
                        <path
                          d="M143.498 118.213C143.498 123.59 141.904 128.847 138.916 133.319C135.928 137.79 131.682 141.275 126.713 143.333C121.745 145.391 116.278 145.93 111.004 144.88C105.729 143.831 100.885 141.242 97.0819 137.439C93.2794 133.636 90.6898 128.792 89.6406 123.517C88.5915 118.243 89.1299 112.776 91.1879 107.808C93.2458 102.839 96.7309 98.5928 101.202 95.6052C105.674 92.6175 110.931 91.0228 116.308 91.0228C123.519 91.0228 130.435 93.8875 135.534 98.9866C140.634 104.086 143.498 111.002 143.498 118.213Z"
                          fill="#6DD1B0"
                        />
                        <path
                          d="M212.709 63.8329C212.709 71.492 210.438 78.9792 206.183 85.3475C201.928 91.7158 195.879 96.6793 188.803 99.6102C181.727 102.541 173.941 103.308 166.429 101.814C158.917 100.319 152.017 96.6311 146.601 91.2153C141.185 85.7994 137.497 78.8992 136.003 71.3872C134.509 63.8752 135.276 56.0889 138.207 49.0128C141.138 41.9367 146.102 35.8888 152.47 31.6338C158.839 27.3787 166.326 25.1077 173.985 25.1079C179.071 25.1079 184.106 26.1096 188.804 28.0557C193.503 30.0018 197.772 32.8543 201.368 36.4503C204.963 40.0462 207.816 44.3152 209.762 49.0136C211.708 53.7119 212.709 58.7475 212.709 63.8329Z"
                          fill="#6DD1B0"
                        />
                        <path
                          d="M167.602 93.7999L192.347 70.4689L169.775 46.5289L144.388 70.4649C143.213 77.5679 139.739 84.089 134.501 89.0277C129.262 93.9664 122.548 97.0498 115.388 97.8049L92.9982 118.919L115.571 142.86L138.786 120.971C139.986 113.927 143.447 107.464 148.646 102.562C153.845 97.6598 160.499 94.584 167.602 93.7999Z"
                          fill="#6DD1B0"
                        />
                        <path
                          d="M174.052 129.463C173.962 129.463 173.874 129.457 173.784 129.456C171.583 139.784 166.601 149.314 159.377 157.016C152.152 164.717 142.959 170.298 132.793 173.153C122.626 176.009 111.873 176.031 101.694 173.217C91.516 170.404 82.3005 164.862 75.0439 157.19C67.7874 149.518 62.7659 140.009 60.5225 129.69C58.279 119.371 58.8989 108.635 62.3151 98.6432C65.7314 88.6511 71.8139 79.7829 79.9052 72.9974C87.9965 66.2119 97.7887 61.7672 108.223 60.1439C108.838 48.2564 112.66 36.7571 119.283 26.8665C125.906 16.9758 135.083 9.06263 145.84 3.96685C121.262 -2.26545 95.3251 -0.349352 71.9297 9.42696C48.5343 19.2033 28.9455 36.3113 16.1093 58.178C3.27316 80.0447 -2.1164 105.488 0.751212 130.681C3.61882 155.874 14.5886 179.455 32.0107 197.878C49.4327 216.3 72.3653 228.568 97.3593 232.837C122.353 237.105 148.058 233.143 170.606 221.547C193.155 209.95 211.329 191.346 222.395 168.533C233.461 145.719 236.821 119.929 231.969 95.0418C226.305 105.459 217.935 114.155 207.742 120.213C197.549 126.271 185.91 129.467 174.052 129.463Z"
                          fill="white"
                        />
                      </svg>
                      Realtime - Queues
                    </label>
                    {isLoading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <DualListDragDropMemoized
                        usersOptions={queuesDropableList}
                        setUsersOptions={setQueuesDropableList}
                        usersSelected={queuesDropableListSelected}
                        setUsersSelected={setQueuesDropableListSelected}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="font-semibold text-sm mt-[6vh]">
          {response && response.status && response.message ? (
            <>
              {response.status === "error" ? (
                <div className="text-red-500">{response.message}</div>
              ) : (
                <div className="text-green-600">
                  {response.status === "success" && countdown > 0
                    ? `User added successfully: closing in ${countdown} second(s)...`
                    : response.message}
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="justify-between w-full items-center flex mt-2">
          <button
            onClick={() => setShowAdminAddUser(false)}
            className="rounded-full  font-semibold py-1 px-6 text-xl"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className={`rounded-full font-semibold dark:text-white py-1 px-6 text-xl`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                  role="status"
                ></div>
                <span className="visually-hidden ml-3">Loading...</span>
              </div>
            ) : (
              "Apply"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminAddUser;
