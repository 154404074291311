import LogoWhite from "../../assets/images/LogoWhite.svg";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../helpers/azureConfig";
import ValidateMsalService from "../../services/login/validateMsal";

const Login = () => {
  const [error, setError] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { instance } = useMsal();


  const handleAzureLogin = async () => {
    setLoading(true);
    try {
      const {
        account: { username: user },
        tenantId,
      } = await instance.loginPopup(loginRequest);
      const data = { user, tenantId };

      const {
        data: { accessToken },
      } = await ValidateMsalService.validateMsal({ data });

      localStorage.setItem("token", accessToken);
      navigate("/realtime", { replace: true });
    } catch (error) {
      setError("Some error occurred while trying to login with MS.");
    } finally {
      setLoading(false);
    }
  };

  const checkAuthentication = useCallback(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      if (token) {
        navigate("/realtime", { replace: true });
      } else {
        handleAzureLogin(); 
      }
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);


  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return (
    <div className="bg-black">
      <section className="z-auto max-w-2xl mx-auto bg-clip-content justify-center content-center flex flex-col">
        <div className="absolute z-20 inset-0 w-full bg-black bg-[linear-gradient(to_right,#ffffff30_1px,transparent_1px),linear-gradient(to_bottom,#ffffff30_1px,transparent_1px)] bg-[size:44px_44px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        <div className="z-40 h-screen flex flex-col gap-8 justify-center items-center">
          <img src={LogoWhite} width={300} height={200} alt="logo-login"></img>
          <div className="bg-white p-8 rounded-xl flex flex-col gap-2 w-[80%] lg:w-[450px] max-w-2xl mx-auto">
            <p className="text-xl text-black text-center font-extralight">
              Welcome to Vooster Flex
            </p>
            <hr className="mt-8 bg-black [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></hr>

            <p className="text-sm text-red-600">{error || <>&nbsp;</>}</p>
            <button
              onClick={handleAzureLogin}
              className="relative inline-flex h-12 overflow-hidden rounded-md p-[1.5px] focus:outline-none focus:ring-4 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
            >
              <span className="absolute inset-[-1000%] animate-[spin_4s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#8331A7_0%,#3FCEA6_50%,#8331A7_100%)]" />
              <span
                className={`inline-flex h-full w-full cursor-pointer items-center justify-center gap-4 rounded-md ${
                  loading ? "bg-gray-400" : "bg-black"
                }  transition-all ease-in-out duration-300 hover:bg-[#222222] px-8 py-1 text-sm font-medium text-white backdrop-blur-3xl`}
              >
                <img
                  alt=""
                  width={30}
                  height={30}
                  src="https://cdn-icons-png.flaticon.com/512/732/732221.png"
                ></img>
                Login with Microsoft
              </span>
            </button>
          </div>
          <span className="inline-flex animate-background-shine bg-[linear-gradient(110deg,#939393,45%,#f9f9f9,55%,#939393)] bg-[length:200%_100%] bg-clip-text text-md text-transparent">
            Powered by Astroline
          </span>
          <div className=" absolute bottom-4 flex gap-6 pt-4 justify-center">
            <p className="text-xs font-extralight text-slate-400">
              Aviso legal
            </p>
            <p className="text-xs font-extralight text-slate-400">
              Política de Privacidad
            </p>
            <p className="text-xs font-extralight text-slate-400">
              Web Astroline
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
