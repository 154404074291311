import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const RequireAuth = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem("token"),
    isLoading: true,
  });

  const isAuthenticated = useIsAuthenticated();
  let navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuthenticated || !auth.token) {
        navigate("/login");
      }
      setAuth((prevAuth) => ({ ...prevAuth, isLoading: false }));
    };

    checkAuth();
  }, [isAuthenticated, navigate, auth.token]);

  if (auth.isLoading) {
    return <div>Cargando...</div>;
  }

  return isAuthenticated ? children : null;
};

export default RequireAuth;
