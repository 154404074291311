import { Avatar } from "@fluentui/react-components";
import React from "react";

const AvatarComponent = ({ avatarUrl }) => {
  return (
    <Avatar
      size={32}
      name={"User"}
      image={avatarUrl ? { src: avatarUrl } : null}
      className="flex-shrink-0"
    />
  );
};

export default AvatarComponent;
