import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../helpers/azureConfig";
import { useState, useEffect } from "react";

const useAuthToken = () => {
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    const getToken = async () => {
      try {
        const response = await instance.acquireTokenSilent(request);
        setToken(response.accessToken);
      } catch (e) {
        try {
          const response = await instance.acquireTokenPopup(request);
          setToken(response.accessToken);
        } catch (err) {
          setError(err);
          console.error(err);
        }
      }
    };

    getToken();
  }, [instance, accounts]);
  return { token, error };
};

export default useAuthToken;
