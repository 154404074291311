import { Routes, Route, BrowserRouter } from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";

import NotFoundPage from "./pages/notFound";
import Layout from "./layout/Layout";
import LoginPage from "./pages/login";
import RequireAuth from "./services/login/requireAuth";
import RealtimePage from "./pages/realtime";
import AdminPage from "./pages/admin";

function App() {
  return (
    <div className="App">
      <DarkModeProvider>
        <FluentProvider theme={teamsLightTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout>
                      <RealtimePage />
                    </Layout>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/realtime"
                element={
                  <RequireAuth requiredScope="master">
                    <Layout>
                      <RealtimePage />
                    </Layout>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/admin"
                element={
                  <RequireAuth>
                    <Layout>
                      <AdminPage />
                    </Layout>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/not-found"
                element={
                  <RequireAuth>
                    <Layout>
                      <NotFoundPage />
                    </Layout>
                  </RequireAuth>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        </FluentProvider>
      </DarkModeProvider>
    </div>
  );
}

export default App;
