import React, { useEffect, useState } from "react";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Pagination from "../shared/Pagination";
import AdminService from "../../services/admin/admin";
import AdminAddUser from "./AdminAddUser";
import AdminUpdateUser from "./AdminUpdateUser";
import AdminDeleteUser from "./AdminDeleteUser";

const AdminMain = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(27);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [showAdminAddUser, setShowAdminAddUser] = useState(false);
  const [showAdminUpdateUser, setShowAdminUpdateUser] = useState(false);
  const [showAdminDeleteUser, setShowAdminDeleteUser] = useState(false);
  const [selecteduser, setSelecteduser] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const filteredData = data.filter((item) => {
    const searchTermMatch = (item.name?.toLowerCase() || "").includes(
      searchTerm.toLowerCase()
    );

    return searchTermMatch;
  });

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const roleStyle = (roles, roleName) => {
    const hasReadWrite = roles.some((role) => role === `${roleName}.ReadWrite`);
    const hasRead = roles.some((role) => role === `${roleName}.Read`);

    if (hasReadWrite) {
      return "bg-green-500";
    } else if (hasRead) {
      return "bg-blue-500";
    }
    return "bg-gris";
  };

  useEffect(() => {
    AdminService.getUsers()
      .then(({ data: { data } }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
      });
  }, [showAdminAddUser, showAdminUpdateUser, showAdminDeleteUser]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const toggleShowAdminAddUser = () => {
    setShowAdminAddUser(!showAdminAddUser);
  };

  const toggleShowAdminUpdateUser = () => {
    setShowAdminUpdateUser(!showAdminUpdateUser);
  };

  const toggleShowAdminDeleteUser = () => {
    setShowAdminDeleteUser(!showAdminDeleteUser);
  };

  return (
    <div class="flex flex-col h-full w-full">
      <div className="py-5 px-2">
        <div className="flex justify-between items-end">
          <div className="flex">
            <div className="relative flex">
              <button onClick={toggleShowAdminAddUser}>
                <PlusCircleIcon className="h-6 w-6 dark:text-white text-gray-black" />
              </button>
              {showAdminAddUser && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => setShowAdminAddUser(false)}
                ></div>
              )}

              {showAdminAddUser && (
                <AdminAddUser setShowAdminAddUser={setShowAdminAddUser} />
              )}

              {showAdminUpdateUser && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => toggleShowAdminUpdateUser()}
                ></div>
              )}
              {showAdminUpdateUser && (
                <AdminUpdateUser
                  setShowAdminUpdateUser={setShowAdminUpdateUser}
                  userSelected={selecteduser}
                />
              )}
              {showAdminDeleteUser && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => toggleShowAdminDeleteUser()}
                ></div>
              )}
              {showAdminDeleteUser && (
                <AdminDeleteUser
                  setShowAdminDeleteUser={setShowAdminDeleteUser}
                  userSelected={selecteduser}
                />
              )}
            </div>

            <div className="w-px h-6 bg-gray-300 mx-2"></div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by user..."
              className=" text-gray-black input input-bordered border border-black bg-[#F7F8FC] dark:bg-black rounded-full text-sm dark:text-white px-2 py-0.5"
            />
          </div>
          <div className="flex items-end">
            <div className="flex">
              <span className="text-xs dark:text-white pt-1 text-gray-black">
                <div>
                  <div className="flex">
                    <div
                      className={`h-3 w-3 rounded-full bg-green-500 mr-1`}
                    ></div>
                    Read and Write Permissions
                  </div>
                  <div className="flex">
                    <div
                      className={`h-3 w-3 rounded-full bg-blue-500 mr-1`}
                    ></div>
                    Read Permissions
                  </div>
                  <div className="flex">
                    <div className={`h-3 w-3 rounded-full bg-gris mr-1`}></div>
                    Without Permissions
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="p-2 w-full lg:hidden justify-end flex text-right text-gray-black">
          <span className="text-xs dark:text-white pt-1 flex">
            <div>
              <div className="flex">
                <div className={`h-3 w-3 rounded-full bg-green-500 mr-1`}></div>
                Read and Write Permissions
              </div>
              <div className="flex">
                <div className={`h-3 w-3 rounded-full bg-blue-500 mr-1`}></div>
                Read Permissions
              </div>
              <div className="flex">
                <div className={`h-3 w-3 rounded-full bg-gris mr-1`}></div>
                Without Permissions
              </div>
            </div>
          </span>
        </div>
      </div>

      <div class="overflow-x-auto hidden sm:block">
        <table class="min-w-full table-auto text-gray-black dark:text-white border-b">
          <thead class="border-b sticky">
            <tr>
              <th class="text-left px-4 py-2">First Name</th>
              <th class="text-left px-4 py-2">Last Name</th>
              <th class="text-left px-4 py-2">UPN</th>
              <th class="text-left px-4 py-2">Realtime</th>
              <th class="text-left px-4 py-2">Stats</th>
              <th class="text-left px-4 py-2">CDR</th>
              <th class="text-left px-4 py-2">API</th>
              <th class="text-left px-4 py-2">User</th>
              <th class="text-left px-4 py-2">Created By</th>
              <th class="text-left px-4 py-2">Created At</th>
              <th class="text-left px-4 py-2">Updated By</th>
              <th class="text-left px-4 py-2">Updated At</th>
              <th class="text-left px-4 py-2">Actions</th>
            </tr>
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan="100%">
                  <div className="loading-bar"></div>
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {isUnauthorized ? (
                <tr>
                  <td colSpan="13" className="text-center ">
                    <div className="flex justify-center items-center w-full bg-red-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                          stroke-width="0"
                          fill="currentColor"
                        ></path>
                      </svg>
                      - Insufficient permissions to perform this action
                    </div>
                  </td>
                </tr>
              ) : (
                <tbody class="bg-white dark:bg-[#191919]">
                  {currentItems.length === 0 ? (
                    <tr>
                      <td colSpan="13" className="text-center py-2">
                        <div className="flex justify-center items-center w-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path
                              d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                              stroke-width="0"
                              fill="currentColor"
                            ></path>
                          </svg>
                          - No users found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {currentItems.map((item, index) => (
                        <tr
                          key={index}
                          class="border-b dark:border-white text-xs font-medium"
                        >
                          <td class="px-4 py-2">{item.name}</td>
                          <td class="px-4 py-2">{item.surname1}</td>
                          <td class="px-4 py-2">{item.email}</td>
                          <td class="px-4 py-2">
                            <div
                              className={`h-3 w-3 rounded-full ${roleStyle(
                                item.roles,
                                "Realtime"
                              )}`}
                            ></div>
                          </td>
                          <td class="px-4 py-2">
                            <div
                              className={`h-3 w-3 rounded-full ${roleStyle(
                                item.roles,
                                "Stats"
                              )}`}
                            ></div>
                          </td>
                          <td class="px-4 py-2">
                            <div
                              className={`h-3 w-3 rounded-full ${roleStyle(
                                item.roles,
                                "CDR"
                              )}`}
                            ></div>
                          </td>
                          <td class="px-4 py-2">
                            <div
                              className={`h-3 w-3 rounded-full ${roleStyle(
                                item.roles,
                                "API"
                              )}`}
                            ></div>
                          </td>
                          <td class="px-4 py-2">
                            <div
                              className={`h-3 w-3 rounded-full ${roleStyle(
                                item.roles,
                                "User"
                              )}`}
                            ></div>
                          </td>

                          <td className="px-4 py-2">{item.createdBy}</td>

                          <td class="px-4 py-2">
                            {new Date(item.createdAt).toLocaleString(
                              undefined,
                              {
                                timeZone:
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </td>
                          <td className="px-4 py-2">{item.updatedBy}</td>
                          <td class="px-4 py-2">
                            {new Date(item.updatedAt).toLocaleString(
                              undefined,
                              {
                                timeZone:
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </td>
                          <td className="px-4 py-2 items-center justify-center flex">
                            <PencilSquareIcon
                              className="h-5 w-5 mx-2 cursor-pointer"
                              onClick={() => {
                                setShowAdminUpdateUser(true);
                                setSelecteduser(item);
                              }}
                            />
                            <TrashIcon
                              className="h-5 w-5 mx-2 cursor-pointer"
                              onClick={() => {
                                setShowAdminDeleteUser(true);
                                setSelecteduser(item);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              )}
            </>
          )}
        </table>
      </div>

      <div class="sm:hidden overflow-y-scroll">
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <div className="loading-bar"></div>
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {currentItems.length === 0 ? (
              <tr className="">
                <td colSpan="1" className="text-center py-2">
                  <div className="flex justify-center  items-center w-full dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                        stroke-width="0"
                        fill="currentColor"
                      ></path>
                    </svg>
                    - No users found
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentItems.map((item, index) => (
                  <div
                    key={index}
                    class="bg-white dark:bg-gray-900 p-4 mb-2 rounded-lg shadow dark:text-white"
                  >
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Name:</span>
                      <span>{item.name}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Description:</span>
                      <span>{item.description}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">CLI Received:</span>
                      <span>{item.cliReceived}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Pool Amount:</span>
                      <span>{item.pool}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Color:</span>
                      <span
                        key={item.color}
                        style={{ backgroundColor: item.color }}
                        className="inline-flex items-center px-2  text-xs rounded-full font-medium text-black dark:text-white"
                      >
                        {item.color}
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created By:</span>
                      <span
                        className={`inline-flex items-center rounded-full pr-1 text-s`}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.createdBy && <div className="mr-2"></div>}
                          {item.createdBy}
                        </div>
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created At:</span>
                      <span>
                        {new Date(item.createdAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Updated By:</span>
                      <span
                        className={`inline-flex items-center rounded-full pr-1 text-s`}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.updatedBy && <div className="mr-2"></div>}
                          {item.updatedBy}
                        </div>
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-bold">Updated At:</span>
                      <span>
                        {new Date(item.updatedAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
      />
    </div>
  );
};

export default AdminMain;
