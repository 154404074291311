import RealtimeMain from "../components/realtime/RealtimeMain";

const RealtimePage = () => {
  return (
    <>
      <RealtimeMain />
    </>
  );
};
export default RealtimePage;
