/* eslint-disable default-case */

import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZ_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AZ_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZ_APP_LOGIN_PAGE_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    //storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["Presence.Read.All", "User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
