import React from "react";
import "./index.css";
import App from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { createRoot } from "react-dom/client";
import { msalConfig } from "./helpers/azureConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);
