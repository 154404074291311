import AdminMain from "../components/admin/AdminMain";

const AdminPage = () => {
  return (
    <>
      <AdminMain />
    </>
  );
};

export default AdminPage;
