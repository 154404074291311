import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useState, useRef, useEffect, Fragment } from "react";
import { FaFilter } from "react-icons/fa";

function useOutsideAlerter(ref, onClose) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
}

const animatedComponents = makeAnimated();

const RealtimeFilters = ({
  setFilters,
  filters,
  setShowFilters,
  agentOptions,
  queueOptions,
}) => {
  const [orderMethod, setOrderMethod] = useState(filters.orderMethod || "name");

  const [selectedAgentFilters, setSelectedAgentFilters] = useState(() =>
    agentOptions.filter((option) => filters.agents.includes(option.value))
  );

  const [selectedQueueFilters, setSelectedQueueFilters] = useState(() =>
    queueOptions.filter((option) => filters.queues.includes(option.value))
  );

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowFilters(false));
  const [selectedFilters, setSelectedFilters] = useState(filters);

  const applyFilters = (selectedFilters) => {
    const transformFilters = (selected) => {
      return Object.keys(selected).reduce((acc, key) => {
        if (
          Array.isArray(selected[key]) &&
          selected[key].length > 0 &&
          selected[key][0].hasOwnProperty("value")
        ) {
          acc[key] = selected[key].map((option) => option.value);
        } else {
          acc[key] = selected[key];
        }
        return acc;
      }, {});
    };

    const transformedFilters = transformFilters(selectedFilters);

    setFilters(transformedFilters);
  };

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      orderMethod,
      agents: selectedAgentFilters,
      queues: selectedQueueFilters,
    }));
  }, [orderMethod, selectedAgentFilters, selectedQueueFilters]);

  return (
    <>
      <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white dark:bg-[#191919] rounded-lg shadow-lg p-4 max-w-lg w-full">
          <div className=" gap-4 w-full">
            <div className="mb-4 w-full">
              <label className="text-2xl font-semibold  dark:text-white flex items-center mb-6">
                <FaFilter className="dark:text-white mr-2" />
                Filters
              </label>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-semibold dark:text-white">
                Queues
              </label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={queueOptions}
                className="text-sm"
                onChange={(selected) => setSelectedQueueFilters(selected || [])}
                value={selectedQueueFilters}
                getOptionLabel={(option) => (
                  <span className="inline-flex items-center px-2  text-xs rounded-xl font-medium mr-1 mb-1 text-black">
                    {option.label}
                  </span>
                )}
              />

              <label className="block mb-2 text-sm font-semibold dark:text-white mt-4">
                Agents
              </label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={agentOptions}
                className="text-sm"
                onChange={(selected) => setSelectedAgentFilters(selected || [])}
                getOptionLabel={(option) => (
                  <span className="inline-flex items-center px-2  text-xs rounded-xl font-medium mr-1 mb-1 text-black">
                    {option.label}
                  </span>
                )}
                value={selectedAgentFilters}
              />
              <div className="flex flex-col mt-4">
                <label className="inline-flex items-center mt-3">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-600 bg-blue-200"
                    name="orderMethod"
                    value="name"
                    checked={orderMethod === "name"}
                    onChange={() => setOrderMethod("name")}
                  />
                  <span className="ml-2 text-gray-700 text-xs font-semibold dark:text-white">
                    Order by Name
                  </span>
                </label>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-600"
                    name="orderMethod"
                    value="status"
                    checked={orderMethod === "status"}
                    onChange={() => setOrderMethod("status")}
                  />
                  <span className="ml-2 text-gray-700 text-xs font-semibold dark:text-white">
                    Order by Status
                  </span>
                </label>
              </div>
              <div className="flex justify-between mt-20">
                <button
                  className="px-4 dark:text-white py-2 text-sm rounded font-medium "
                  onClick={() => setShowFilters(false)}
                >
                  Close
                </button>
                <button
                  className="px-4 py-2 dark:text-white text-sm rounded font-medium "
                  onClick={() => {
                    applyFilters(selectedFilters);
                    setShowFilters(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealtimeFilters;
