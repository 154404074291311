import { useEffect, useRef, useState } from "react";
import useAuthToken from "../../hooks/useAuthToken";
import VoosterLogoWhite from "../../assets/images/LogoWhite.svg";
import VoosterLogoBlack from "../../assets/images/LogoBlack.svg";
import { useDarkMode } from "../../context/DarkModeContext";

import RealtimeAgents from "./RealtimeAgents";
import RealtimeQueues from "./RealtimeQueues";
import RealtimeService from "../../services/realtime/realtime";
import RealtimeFilters from "./RealtimeFilters";
import { Avatar } from "@fluentui/react-components";

const RealtimeMain = () => {
  const { darkMode } = useDarkMode();

  const [logo, setLogo] = useState(VoosterLogoBlack);

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    if (isDarkMode) {
      document.body.classList.add("dark");
      setLogo(VoosterLogoWhite);
    } else {
      document.body.classList.remove("dark");
      setLogo(VoosterLogoBlack);
    }
  }, [darkMode]);
  const { token } = useAuthToken();
  const [users, setUsers] = useState([]);

  const [queues, setQueues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    agents: [],
    queues: [],
  });

  const clearFilters = () => {
    setFilters({
      agents: [],
      queues: [],
    });
  };

  useEffect(() => {
    const updatePresence = async () => {
      if (token && users.length > 0) {
        RealtimeService.realtime({ msalT: token })
          .then(
            ({
              data: {
                message: { queues, agents },
              },
            }) => {
              const updatedUsers = users.map((user) => {
                const foundAgent = agents.find((agent) => agent.id === user.id);
                return {
                  ...user,
                  status: foundAgent ? foundAgent.activityCode : "offline",
                  activity: foundAgent
                    ? foundAgent.activityDescription
                    : "Unknown",
                  loading: foundAgent ? foundAgent.loading : user.loading,
                };
              });
              setUsers(updatedUsers);
              setQueues(queues);
            }
          )
          .catch((e) => {});
      }
    };

    const intervalId = setInterval(() => {
      updatePresence();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [users, token]);

  const contentRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (contentRef.current) {
      if (!document.fullscreenElement) {
        contentRef.current.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const agentOptions = users.map((user) => ({
    value: user.id,
    label: (
      <>
        <Avatar
          size={20}
          name={user.name}
          image={user.photoUrl ? { src: user.photoUrl } : null}
        ></Avatar>
        &nbsp;&nbsp;
        {user.name}
      </>
    ),
  }));
  const queueOptions = queues.map((queue) => ({
    value: queue.name,
    label: queue.name,
  }));
  const containerStyles = isFullScreen ? "h-screen" : "h-[95vh]";

  useEffect(() => {
    const loadPermissionsAndData = async () => {
      if (token) {
        try {
          const response = await RealtimeService.getPermissions();
          const {
            data: { permissions },
          } = response;

          if (permissions?.queues?.length) {
            setQueues(permissions.queues);
          }
          if (permissions?.agents?.length) {
            setUsers(permissions.agents);
          }

          setIsLoading(false);
        } catch (error) {
          console.error("Error loading permissions:", error);
        }
      }
    };

    loadPermissionsAndData();
  }, [token]);

  const filterAndSortData = () => {
    const filteredQueues = queues.filter(
      (queue) =>
        filters.queues.length === 0 || filters.queues.includes(queue.name)
    );

    let filteredUsers = users.filter(
      (user) => filters.agents.length === 0 || filters.agents.includes(user.id)
    );

    if (filters.orderMethod === "name") {
      filteredUsers.sort((a, b) => a?.name?.localeCompare(b.name));
    } else if (filters.orderMethod === "status") {
      filteredUsers.sort((a, b) => a?.status?.localeCompare(b.status));
    }

    return { filteredQueues, filteredUsers };
  };
  const { filteredQueues, filteredUsers } = filterAndSortData();

  const numberOfFiltersApplied = Object.values(filters).reduce(
    (totalLength, value) => {
      if (Array.isArray(value)) {
        return totalLength + value.length;
      }

      return totalLength + (value !== null ? 1 : 0);
    },
    0
  );

  return (
    <div
      className={`${containerStyles} w-full dark:text-white transition-all duration-300`}
    >
      {isLoading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 dark:border-[#6DD1B0] border-purple-400"></div>
          <img alt="a" src={logo} className="rounded-full h-28 w-28 absolute" />
        </div>
      ) : (
        <>
          <div className="h-10 w-full flex justify-between p-3">
            <div className="flex">
              {numberOfFiltersApplied > 0 && (
                <div className="mr-2 h-6 w-6 dark:bg-yellow-200/20 dark:text-yellow-200 text-amber-800 bg-amber-800/20 text-xs rounded-full text-center py-1">
                  {numberOfFiltersApplied}
                </div>
              )}
              <button onClick={() => setShowFilters(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                  />
                </svg>
              </button>
              {showFilters && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => setShowFilters(false)}
                ></div>
              )}

              {showFilters && (
                <RealtimeFilters
                  setShowFilters={setShowFilters}
                  filters={filters}
                  setFilters={setFilters}
                  agentOptions={agentOptions}
                  queueOptions={queueOptions}
                />
              )}
              {numberOfFiltersApplied > 0 && (
                <div className="flex">
                  <div className="w-px h-6 bg-gray-300 mx-2"></div>
                  <button
                    className="text-xs h-6 dark:bg-yellow-200/20 dark:text-yellow-200 text-amber-800 bg-amber-800/20 rounded-xl px-2 items-end justify-end text-end"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </button>
                </div>
              )}
            </div>
            <button onClick={toggleFullScreen}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
                />
              </svg>
            </button>
          </div>
          <div className="flex" ref={contentRef}>
            <div className="w-1/3">
              <RealtimeQueues queues={filteredQueues} />
            </div>
            <div className="w-2/3 flex flex-wrap gap-1">
              <RealtimeAgents users={filteredUsers} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RealtimeMain;
