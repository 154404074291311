const GRAPH_BASE = process.env.REACT_APP_GRAPH_URL;

const getUsers = async (token) => {
  return callGraph(`users`, token).then((response) => response.json());
};

const getPhoto = async (token, user) => {
  const photo = await callGraph(`users/${user}/photos/648x648/$value?`, token);
  if (photo) {
    let blob = await photo.blob();
    return URL.createObjectURL(blob);
  }
};

const getMyPhoto = async (token) => {
  const photo = await callGraph(`me/photo/$value`, token);
  if (photo) {
    let blob = await photo.blob();
    return URL.createObjectURL(blob);
  }
};

const callGraph = async (apiPath, token) => {
  try {
    let resp = await fetch(`${GRAPH_BASE}${apiPath}`, {
      headers: { authorization: `bearer ${token}` },
    });

    return resp;
  } catch (error) {}
};

const getUserPresence = async (token, userIds) => {
  try {
    const response = await fetch(
      `${GRAPH_BASE}/communications/getPresencesByUserId`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ids: userIds,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch user presence: ${response.statusText}`);
    }

    const data = await response.json();
    const availabilityStatus = data.value.reduce((acc, presence) => {
      acc[presence.id] = presence.availability;
      return acc;
    }, {});
    const activityStatus = data.value.reduce((acc, presence) => {
      acc[presence.id] = presence.activity;
      return acc;
    }, {});

    return { availabilityStatus, activityStatus };
  } catch (error) {
    return {};
  }
};

const GraphService = {
  getPhoto,
  callGraph,
  getUsers,
  getUserPresence,
  getMyPhoto,
};

export default GraphService;
